<div>
  <div class="content-container">
    <div class="title">References</div>
    <p>
      1. Allergan Aesthetics, The Future of Aesthetics global trends report.
      ALL-AGNA-220005 March 2022.
    </p>
    <p>
      2. American Society of Plastic Surgeons, “Plastic Surgery Statistics
      Report 2020”,
      <a href="https://www.plasticsurgery.org/documents/News/Statistics/2020/cosmetic-procedures-ethnicity-2020.pdf" target="_blank">https://www.plasticsurgery.org/documents/News/Statistics/2020/cosmetic-procedures-ethnicity-2020.pdf</a>
      Accessed May 2022.
    </p>
    <p>
      3. BAAPS, “Post-pandemic panic – BAAPS issues a stark warning to the
      public: “Don’t fall victim to unscrupulous providers selling surgery as a
      post-lockdown quick fix”, October 2020,
      <a href="https://baaps.org.uk/media/press_releases/1824/postpandemic_panic_baaps_issues_a_stark_warning_to_the_public_dont_fall_
      victim_to_unscrupulous_providers_selling_surgery_as_a_postlockdown_quick_x" target="_blank">https://baaps.org.uk/media/press_releases/1824/postpandemic_panic_baaps_issues_a_stark_warning_to_the_public_dont_fall_
      victim_to_unscrupulous_providers_selling_surgery_as_a_postlockdown_quick_x</a>.
      Accessed May 2022.
    </p>

    <span>
      Material funded by Allergan Aesthetics, an Abbvie company.<br />
      Date of Prep: May 2022 <br />
      Job Code: ALL-ABBV-210231 <br />
    </span>
  </div>
</div>
