<main class="container-fluid" role="main">
    <div class="row">
        <div class="col-12 col-md-8 top-row">
            <app-country-selector-section></app-country-selector-section>
        </div>
        <div class="col-12 col-md-4 top-row trends">
            <app-trends-section></app-trends-section>
        </div>

        <div class="col-12 col-md-4 bottom-row">
            <app-careers-section></app-careers-section>
        </div>
        <div class="col-12 col-md-4 bottom-row contact-us">
            <app-contact-us-section></app-contact-us-section>
        </div>
        <div class="col-12 col-md-4 bottom-row">
            <app-about-us-section></app-about-us-section>
        </div>

        <div class="col-12 footer-row">
            <app-footer></app-footer>
        </div>
    </div>
</main>