import {
  faLongArrowRight,
  faAngleDown,
  faAngleUp,
  faTimes,
  faChevronRight,
  faSpinnerThird,
  faChevronDown,
  faChevronUp
} from '@fortawesome/pro-light-svg-icons';

export const iconTypes = {
  arrowRight: faLongArrowRight,
  caretDown: faAngleDown,
  caretUp: faAngleUp,
  close: faTimes,
  chevronRight: faChevronRight,
  loading: faSpinnerThird,
  chevronDown: faChevronDown,
  chevronUp: faChevronUp
};
