<ng-template #contentCopy>
  Modern men are increasingly comfortable looking after both their body and
  their looks. Despite undergoing fewer non-invasive procedures globally vs.
  females,<sup>2</sup> demand for male aesthetics treatments is rising through a
  broader spectrum of possibilities.<sup>3</sup>
</ng-template>

<div #sectionContainer id="new-masculine" class="section-container">
  <div class="content">
    <section-content pageNum="2" prevSectionId="diversity" nextSectionId="gender-inclusive">
      <ng-container title>The New <app-text-animate text="Masculine"></app-text-animate></ng-container>
      <ng-container content *ngTemplateOutlet="contentCopy"></ng-container>
    </section-content>
  </div>

  <div class="model-container">
    <div class="box-light"></div>
    <div class="box-dark"></div>

    <img class="model" src="https://media.allergan.com/allerganaesthetics/globallanding/model3.png" />
  </div>

  <div class="content-mobile">
    <ng-container content *ngTemplateOutlet="contentCopy"></ng-container>
  </div>
</div>