<ng-template #contentCopy>
  Practitioners the world over are tipping the body as the new frontier for
  growth and innovation. Body contouring, treatments targeting cellulite, skin
  quality and laxity will be the top goals in the future as more advanced and
  effective innovations become available.
</ng-template>

<div #sectionContainer id="new-body-frontiers" class="section-container">
  <div class="content">
    <section-content pageNum="6" title="New-Body Frontiers" prevSectionId="elevated-experiences"
      nextSectionId="aesthetics">
      <ng-container title>New-Body <app-text-animate text="Frontiers"></app-text-animate></ng-container>
      <ng-container content *ngTemplateOutlet="contentCopy"></ng-container>
    </section-content>
  </div>

  <div class="model-container">
    <div class="box-light"></div>
    <div class="box-dark"></div>
    <img class="model" src="https://media.allergan.com/allerganaesthetics/globallanding/model6.png" />
  </div>


  <div class="content-mobile">
    <ng-container content *ngTemplateOutlet="contentCopy"></ng-container>
  </div>
</div>