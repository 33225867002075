<ng-template #contentCopy>
  People of all ethnicities are seeking out aesthetic treatments,<sup>2</sup>
  and want to see themselves reflected and represented more in the future. For
  every patient, treating their unique needs with a truly personalised approach
  is vital.
</ng-template>

<div #sectionContainer id="diversity" class="section-container">
  <div class="content">
    <section-content
      pageNum="1"
      nextSectionId="new-masculine"
      >
        <ng-container title>Diverse <app-text-animate text="Individuality"></app-text-animate></ng-container>
        <ng-container content *ngTemplateOutlet="contentCopy"></ng-container>
    </section-content>
  </div>

  <div class="model-container">
    <div class="box-light"></div>
    <div class="box-dark"></div>
    <img
      class="model"
      src="https://media.allergan.com/allerganaesthetics/globallanding/model1.png"
    />
  </div>

  <div class="content-mobile">
    <ng-container content *ngTemplateOutlet="contentCopy"></ng-container>
  </div>
</div>
