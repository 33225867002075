import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trends-intro-component',
  templateUrl: './trends-intro-component.component.html',
  styleUrls: ['./trends-intro-component.component.scss']
})
export class TrendsIntroComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
