<div class="section" role="region" aria-labelledby="country-selector-title">
  <div class="logo">
    <img
      src="/assets/img/logo.png"
      i18n-alt
      alt="Allergan Aesthetics an AbbVie company logo"
    />
  </div>
  <div class="content">
    <h1 i18n id="country-selector-title">Empowering confidence</h1>
    <div class="subtitle" i18n>Choices backed by science.</div>
    <app-country-selector></app-country-selector>
  </div>
</div>
