<main>
  <div class="gradient"></div>
  <app-trends-header-component></app-trends-header-component>
  <div class="container-fluid">
    <div class="col image-col">
      <img
        src="https://media.allergan.com/allerganaesthetics/globallanding/ethics.png"
      />
    </div>
    <div class="col content-col">
      <h1>Aesthetics-Ethics</h1>
      <div class="content-body">
        <p>
          <a
            href="https://media.allergan.com/actavis/actavis/media/allergan-pdf-documents/FINAL_ITEM_(Digital_+_Print)_Moving_the_Needle_on_Ethics_Book.pdf"
            target="_blank"
          >
            Download a copy of the Moving the Needle on Ethics global book.
          </a>
        </p>
        <p>
          <a
            href="https://media.allergan.com/actavis/actavis/media/general/Moving%20the%20Needle%20on%20Ethics%20Book%20US.pdf"
            target="_blank"
          >
            Download a copy of the Moving the Needle on Ethics US book (intended for US audiences)
          </a>
        </p>
        <!--Placeholder Vimeo Embed-->
        <div class="video-container">
          <video width="100%" poster="https://media.allergan.com/allerganaesthetics/globallanding/videoframe_5809.png" controls>
            <source src="https://player.vimeo.com/progressive_redirect/playback/932118055/rendition/720p/file.mp4?loc=external&signature=1aaa8d0fdb2f3a3b076c9760602442ec98f34323d615c09a64bd8993ef405101" type="video/mp4">
          </video>
        </div>
        <!--End Placeholder Vimeo embed-->
        <br/>
        <p>
          With the Global Medical Aesthetics industry set to grow at a faster pace than ever before, with 
          23 million dermal fillers and 14.6 million body procedures predicted by 2025.<sup>[i]</sup> There is an influx 
          of new practitioners, seeking trusted information and education to ensure high standards are upheld. 
          Allergan Aesthetics is therefore launching <strong>‘Moving the Needle on Ethics’</strong> report, which aims to drive 
          positive change and ensure patient care and satisfaction are prioritized to meet the needs of an evolving 
          global aesthetics landscape.
        </p>
        <br />
        <p>
          As an industry leader, Allergan Aesthetics* has participated in the evolution of the industry 
          and remains committed to prioritizing patient safety and high ethical standards. Therefore, as 
          part of the Aesthetics-Ethics program, we invested in research and insight gathe ring to better 
          understand the evolving aesthetics landscape on ethical standards. Social media listening has 
          been undertaken to identify territories and themes of conversation on ethics in aesthetics amongst 
          patients and practitioners.<sup>2†</sup> 1:1, interviews were conducted to better understand the current ethical 
          landscape by gathering insights from Allergan Aesthetics* markets around the world. And we also took 
          part in discussions with aesthetics practitioners representing 13 countries globally. The research 
          confirms the impact of social and cultural shifts in addition to highlighting topics such as social 
          media filters, levels of practitioner expertise and training, overtreatment, and psychological health 
          are permeating conversations.<sup>3†</sup> It also highlights that the industry has a role to play, to prioritize 
          patient safety and satisfaction. 
        </p>
        <br />
        <p>
          We hope this book elevates the conversations around ethics across the industry, inciting action to ‘move the needle on ethics’ to shape the aesthetics industry of the future.
        </p>
      </div>
    </div>
  </div>
  <div class="references">
    <div class="content-container">
      <div class="title">References</div>
      <p>
        [i] Medical Insight. The Global Aesthetic Market Study: XIX. November 2021
      </p>
      <p>
        2 The Future of Aesthetics global trends report. March 2022 ALL-AGNA-220005
      </p>
      <p>
        3 Allergan Aesthetics Data on File. Ethics social listening. October 2022. REF-102346.Allergan Aesthetics
      </p>
      <p>
        4 Unpublished Data - Allergan Medical Institute International – August 2020
      </p>
    </div>
  </div>
  
  <app-footer></app-footer>
</main>