import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-country-selector-section',
  templateUrl: './country-selector-section.component.html',
  styleUrls: ['./country-selector-section.component.scss']
})
export class CountrySelectorSectionComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
