<app-accordion
  title="Contact Us"
  i18n-title
  [maxWidth]="maxWidth"
  [rightAligned]="true"
>
  Find the right contact no matter where you are in the world.
  <div>
    <app-btn
      href="https://www.abbvie.com/contactus.html"
      [style]="buttonStyles.secondary"
      [icon]="iconTypes.arrowRight"
      i18n
      >Get in Touch</app-btn
    >
  </div>
</app-accordion>
