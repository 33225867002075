<app-discover-component></app-discover-component>
<app-diversity-component></app-diversity-component>
<app-new-masculine-component></app-new-masculine-component>
<app-gender-inclusive-component></app-gender-inclusive-component>
<app-ephemeral-expression-component></app-ephemeral-expression-component>
<app-elevated-experiences-component></app-elevated-experiences-component>
<app-new-body-frontiers-component></app-new-body-frontiers-component>
<app-aesthetics-component></app-aesthetics-component>
<app-digital-lens-component></app-digital-lens-component>
<app-beauty-metaverse-component></app-beauty-metaverse-component>
<app-the-end-component></app-the-end-component>
