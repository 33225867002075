<!-- OneTrust Cookies Settings button start -->
<div>
    <a href="https://www.abbvie.com/accessibility-statement.html" target="_blank">Accessibility Statement</a>
    <a href="https://www.abbvie.com/contactus.html" target="_blank">Contact Us</a>
    <a href="https://www.abbvie.com/terms-of-use.html" target="_blank">Terms of Use</a>
    <a href="https://privacy.abbvie/" target="_blank">Privacy Notice</a>
    <a href="https://abbv.ie/PrivacyConsumerHealth" target="_blank">Consumer Health Data Privacy Notice</a>
    <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookies Settings</button>
    <a href="http://#privacy-choices" target=_blank data-alert-key=default></a>
</div>


<!-- OneTrust Cookies Settings button end -->